import React, { useState, createContext, useMemo, useContext, useEffect, useCallback } from 'react';
import DataGrid, { SelectCellFormatter } from 'react-data-grid';
import Filter from 'react-data-grid'
import Toolbar from './data-grid-toolbar';
import ActionButton from './delete-formatter';
import { useFocusRef } from './useFocusref';
import utils from '../app-util';
import RaceSpinner from '../spinner';
import UserFormPopup from '../user-form-popup';
import { toast } from 'react-toastify';
import IdleTimeTracker from '../IdleTimeTrack/IdleTimeTracker';
import UserInfoPopup from './userinfopopup';

const FILTER_ACTIVE_CLASS = 'filter-active';
const FilterContext = createContext(Filter);
const HEADER_CELL_CLASSNAME = "filter-cell";
function FilterRenderer({
    isCellSelected,
    column,
    children
}) {
    const filters = useContext(FilterContext);
    const { ref, tabIndex } = useFocusRef(isCellSelected);
    return (
        <>
            <div data-key={column.key}>{column.name}</div>
            {filters.enabled && <div>{children({ ref, tabIndex, filters })}</div>}
        </>
    );
}

function stopPropagation(e) {
    e.stopPropagation();
}

function rowKeyGetter(row) {
    return row.id;
}

function EmptyRowsRenderer() {
    return (<div style={{ textAlign: 'center', padding: '125px' }}><h3>No data to show</h3></div>);
}

function formatterWrapper(columnKey) {
    return function ({ row, onRowChange, isCellSelected }) {
        let obj = {}
        obj = { ...row }
        obj[columnKey] = !row[columnKey]
        return (
            <SelectCellFormatter
                value={row[columnKey]}
                onChange={() => {
                    onRowChange(obj);
                }}
                onClick={stopPropagation}
                isCellSelected={isCellSelected}
            />
        );
    }
}
function textRenderer() {
    return (p) => {
        return (
            <>
                <FilterRenderer {...p}>
                    {({ _filters, ..._rest }) => (
                        <span></span>
                    )}
                </FilterRenderer>
            </>
        );
    }
}
function filterRendererWrapper(columnKey, setFilters) {
    return (p) => {
        return (
            <>
                <FilterRenderer {...p}>
                    {({ filters, ...rest }) => (
                        <select
                            {...rest}
                            className='filter-inputclass-name'
                            value={filters[columnKey]}
                            onChange={(e) => {
                                let obj = {}
                                obj = { ...filters }
                                obj[columnKey] = e.target.value
                                setFilters(obj)
                            }
                            }
                            onKeyDown={stopPropagation}
                        >
                            <option value="All">All</option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                        </select>
                    )}
                </FilterRenderer>
            </>
        );
    }
}
function columnsBlueprint(filterRendererWrapperr, formatterWrapperFunc, inputHeaderFilterRenderer, rows, deleteUserFunc, setRows, ...arg) {
    return [
        {
            key: 'slno', name: 'Sl. No.', width: 80, headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: textRenderer()
        },
        {
            key: 'app_owner', name: "Application Owner", width: 150, headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: filterRendererWrapperr('app_owner', arg[2]),
            formatter: formatterWrapperFunc('app_owner')
        },
        {
            key: 'admin', name: 'Admin', width: 80, headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: filterRendererWrapperr('admin', arg[2]),
            formatter: formatterWrapperFunc('admin')
        },
        {
            key: 'super_user', name: 'Super User', headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: filterRendererWrapperr('super_user', arg[2]),
            formatter: formatterWrapperFunc('super_user')
        },
        {
            key: 'email', name: 'Email', headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: inputHeaderFilterRenderer,
            formatter(rowObj){
                return (
                    <div title='Click to see the user details' className='email-popover-link' style={{ cursor: 'pointer', overflow: 'clip', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={() => {
                        arg[1]({ open: true, data: rowObj.row })
                    }}>{rowObj.row.email}</div>
                );
            }
        },
        {
            key: 'upstream', name: 'Upstream', headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: filterRendererWrapperr('upstream', arg[2]),
            formatter: formatterWrapperFunc('upstream')
        },
        {
            key: 'downstream', name: 'Downstream', headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: filterRendererWrapperr('downstream', arg[2]),
            formatter: formatterWrapperFunc('downstream')
        },
        {
            key: 'integrated_gas', name: 'Integrated Gas', headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: filterRendererWrapperr('integrated_gas', arg[2]),
            formatter: formatterWrapperFunc('integrated_gas')
        },
        {
            key: 'new_energies', name: 'New Energies', headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: filterRendererWrapperr('new_energies', arg[2]),
            formatter: formatterWrapperFunc('new_energies')
        },
        {
            key: 'proj_and_tech', name: 'Projects And Technology', headerCellClass: HEADER_CELL_CLASSNAME, headerRenderer: filterRendererWrapperr('proj_and_tech', arg[2]),
            formatter: formatterWrapperFunc('proj_and_tech')
        },
        {
            key: 'action', name: 'Actions', width: 50, sortable: false,
            formatter(objProp) {
                return (
                    <ActionButton onClick={(_args) => {
                        deleteUserFunc(rows, objProp, arg[0], setRows);
                    }} />
                );
            }
        }
    ]
}
function filterRows(rows, filters) {
    return rows.filter((r) => {
        return (
            (filters.email ? r.email.toLowerCase().includes(filters.email.toLowerCase()) : true) && (filters.admin !== 'All' ? r.admin.toString() === filters.admin : true) &&
            (filters.app_owner !== 'All' ? r.app_owner.toString() === filters.app_owner : true) &&
            (filters.super_user !== 'All' ? r.super_user.toString() === filters.super_user : true) &&
            (filters.upstream !== 'All' ? r.upstream.toString() === filters.upstream : true) &&
            (filters.downstream !== 'All' ? r.downstream.toString() === filters.downstream : true) &&
            (filters.integrated_gas !== 'All' ? r.integrated_gas.toString() === filters.integrated_gas : true) &&
            (filters.new_energies !== 'All' ? r.new_energies.toString() === filters.new_energies : true) &&
            (filters.proj_and_tech !== 'All' ? r.proj_and_tech.toString() === filters.proj_and_tech : true)
        );
    });
}
// If selected column is SU/Admin and checked, then update the corresponding column as checked or vice-versa
function enableColFields(updatedRow, excludeColumnList, ukey, updatedRowIndex, rows) {
    for (const key in updatedRow) {
        if ((Object.hasOwnProperty.call(updatedRow, key)) && (!excludeColumnList.includes(key))) {
            if (updatedRow[ukey]) {
                updatedRow[key] = true;
            } else {
                updatedRow[key] = false;
            }
        }
    }
    //Update the rows to reflect in UI
    for (let i = updatedRowIndex; i <= updatedRowIndex; i++) {
        rows[i] = { ...rows[i], ...updatedRow };
    }
    return updatedRow
}

function updateRequest(updatedRow, payload, setRows, setLoading, prop) {
    setLoading(true);
    fetch('/update_user/' + updatedRow.email, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    }).then(function (response) {
        if (response.status !== 200) {
            utils.checkSessionStatus(response);
            setLoading(false);
            console.log('Error Status Code On Update: ' + response.status);
            return;
        }
        response.json().then(function (data) {
            if (data.success) {
                toast.success(data.message, {autoClose: 800});
                //Update UI
                setRows(prop);
            } else {
                toast.warn(data.message, {autoClose: 800});
            }
            setLoading(false);
        });
    }).catch(function (err) {
        setLoading(false);
        console.log('Updation Error :', err);
    });
}
function deleteUser(rows, objProp, setLoading, setRows) {
    const indexOfDeletedRow = rows.findIndex(function (item, _i) {
        return item.id === objProp.row.id
    });
    const deletedRow = rows[indexOfDeletedRow];
    if (window.confirm(`Do you want to delete the user "${deletedRow.email}"?`)) {
        setLoading(true);
        fetch('/delete_user/' + deletedRow.email, {
            method: 'DELETE',
            headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
        }).then(function (response) {
            if (response.status !== 200) {
                setLoading(false);
                utils.checkSessionStatus(response);
                console.log('Error Status Code On Delete: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data.success) {
                    toast.success(data.message, {autoClose: 1000});
                    // Delete the element using index and Set the state to update the grid
                    setRows([...rows.slice(0, indexOfDeletedRow), ...rows.slice(indexOfDeletedRow + 1)]);
                    // Refresh the user list if an user deleted
                    loadUsers(setRows, setLoading);
                } else {
                    toast.warn(data.message);
                }
                setLoading(false);
            });
        }).catch(function (err) {
            setLoading(false);
            console.log('Error occured on delete:', err);
        });
    }
}
function togglefilterClass(removeClassIfExist = false) {
    const rdgFilterBtn = document.querySelector(".rdg-filter-btn"), rdgClearFilterBtn = document.querySelector(".rdg-clear-filter-btn");
    document.querySelectorAll(".rdg-cell").forEach((element) => {
        if (element.classList.contains('filter-on')) {
            element.classList.remove('filter-on');
            rdgFilterBtn.classList.remove(FILTER_ACTIVE_CLASS);
            rdgClearFilterBtn.classList.remove(FILTER_ACTIVE_CLASS);
        } else {
            // if the filter is on,if clearfilter clicked it will remove the active class i.e 'filter-on' otherwise it will always add it
            // removeClassIfExist ? element.classList.remove('filter-on') : element.classList.add('filter-on');
            if (removeClassIfExist) {
                element.classList.remove('filter-on')
                rdgFilterBtn.classList.remove(FILTER_ACTIVE_CLASS);
                rdgClearFilterBtn.classList.remove(FILTER_ACTIVE_CLASS);
            } else {
                element.classList.add('filter-on')
                rdgFilterBtn.classList.add(FILTER_ACTIVE_CLASS);
                rdgClearFilterBtn.classList.add(FILTER_ACTIVE_CLASS);
            }
        }
    });
}
function handleHeaderCell(e, setSortColumns) {
    if (!e.target.classList.contains("filter-inputclass-name")) {
        // Enable filter functionality by passing the columnkey and sort direction
        let columnKey = e.currentTarget.children[0].textContent.split(' ').join('_').toLowerCase();

        if (columnKey === 'projects_and_technology') {
            columnKey = 'proj_and_tech';
        } else if (columnKey === 'sl._no.') {
            columnKey = 'slno';
        }
        const sort = e.currentTarget.getAttribute("aria-sort", "descending")
        if (sort === "descending") {
            setSortColumns([{ columnKey: columnKey, direction: 'ASC' }]);
        } else if (sort === "ascending") {
            setSortColumns([])
        } else {
            setSortColumns([{ columnKey: columnKey, direction: 'DESC' }]);
        }
    }
}
function handleOnGridRowUpdate(prop, updated, rows) {
    const updatedRowIndex = updated.indexes[0];
    const updatedRow = prop[updatedRowIndex];
    const ukey = updated.column.key
    let payload = null;

    if (ukey === 'super_user') {
        payload = enableColFields(updatedRow, ["app_owner","admin", "email", "id", "action", "slno"], ukey, updatedRowIndex, rows)
    } else if (ukey === 'app_owner') {
        payload = enableColFields(updatedRow, ["email", "id", "slno", "action"], ukey, updatedRowIndex, rows)
    } else if (ukey === 'admin') {
        payload = enableColFields(updatedRow, ["app_owner", "email", "id", "slno", "action"], ukey, updatedRowIndex, rows)
    }else if (ukey === 'downstream' || ukey === "new_energies") {
        payload = enableColFields(updatedRow, ["slno", "email", "id", "action", "app_owner", "super_user", "admin", "upstream", "integrated_gas", "proj_and_tech"], ukey, updatedRowIndex, rows)
    } else {
        payload = updatedRow;
    }
    return [updatedRow, payload]
}
function loadUsers(setRows, setLoading) {
    setLoading(true);
    fetch('/allusers', {
        headers: { "X-CSRFToken": utils.csrf(), 'Authorization': 'Bearer ' + window.sessionStorage.getItem("_at") }
    })
        .then(function (response) {
            if (response.status !== 200) {
                setLoading(false);
                utils.checkSessionStatus(response);
                console.log('Error Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                // Format the data to be grid ready
                setRows(data);
                setLoading(false);
            });
        }).catch(function (err) {
            setLoading(false);
            console.log('Fetch Error :', err);
        });
}
export default function UserDataGrid() {
    const [rows, setRows] = useState([]);
    const filterObj = {
        email: '', admin: 'All', app_owner:"All", super_user: 'All', upstream: 'All', downstream: 'All', integrated_gas: 'All', new_energies: 'All', proj_and_tech: 'All', enabled: false
    };
    const [filters, setFilters] = useState(filterObj);
    const [loading, setLoading] = useState(false);
    const [openuserpopup, setOpenuserpopup] = useState(false);
    const [userinfopopup, setUserinfopopup] = useState({open: false, data: {}});
    useEffect(() => {
        loadUsers(setRows, setLoading);
        setTimeout(() => {
            document.querySelectorAll('.rdg-cell[role="columnheader"]').forEach((e) => {
                e.addEventListener('click', headerCellOnClick);
            })
        }, 2000);
    }, [])
    function onRefreshClick() {
        loadUsers(setRows, setLoading);
    }
    function headerCellOnClick(e) {
        handleHeaderCell(e, setSortColumns);
    }
    const inputHeaderFilterRenderer = (p) => (
        <>
            <FilterRenderer {...p}>
                {({ filters, ...rest }) => (
                    <input
                        {...rest}
                        className='filter-inputclass-name'
                        value={filters.email}
                        onChange={(e) =>
                            setFilters({
                                ...filters,
                                email: e.target.value
                            })
                        }
                        onKeyDown={stopPropagation}
                    />
                )}
            </FilterRenderer>
        </>
    );


    const columns = useMemo(() => {
        return columnsBlueprint(filterRendererWrapper, formatterWrapper, inputHeaderFilterRenderer, rows, deleteUser, setRows, setLoading, setUserinfopopup, setFilters);
    }, [rows]);


    const filteredRows = useMemo(() => {
        return filterRows(rows, filters);
    }, [rows, filters]);

    function onAddRow(_param) {
        setOpenuserpopup(true);
    }
    function userFormAfterSubmit(_data) {
        /**
         *  rows.push({ id: rows.length+1, slno: rows.length+1, email: data.email, admin: data.admin, super_user: data.super_user, upstream: data.upstream, 
         *       downstream: data.downstream, integrated_gas: data.integrated_gas, 
         *      new_energies: data.new_energies, proj_and_tech: data.proj_and_tech
         *   });
         *   setRows(rows);
         */
        // Refresh the user list if new user added
        loadUsers(setRows, setLoading);
        toggleFilters(false);
        setOpenuserpopup(false);
    }
    function onGridRowsUpdated(prop, updated) {
        if (prop[updated.indexes[0]].app_owner === true && updated.column.key !== 'app_owner') {
            toast.warn("You can change permission after removing you from owner", {autoClose: 1000});
        } else if (prop[updated.indexes[0]].admin === true && updated.column.key !== 'admin' && updated.column.key !== 'app_owner') {
            toast.warn("You can change permission after removing you from admin", {autoClose: 1000});
        } else {
            const [updatedRow, payload] = handleOnGridRowUpdate(prop, updated, rows);
            updateRequest(updatedRow, payload, setRows, setLoading, prop);
        }
    }

    function toggleFilters(v = true) {
        togglefilterClass();
        setFilters((_filters) => ({ ..._filters, enabled: v ? !_filters.enabled : _filters.enabled }));
    }
    function clearFilters() {
        togglefilterClass(true);
        setFilters(filterObj);
    }
    function popupAfterClose(value) {
        // Reset state
        setOpenuserpopup(value);
    }
    const [sortColumns, setSortColumns] = useState([]);
    const onSortColumnsChange = useCallback((sortColumnss) => {
        setSortColumns(sortColumnss.slice(-1));
    }, []);
    const sortedRowss = useMemo(() => {
        if (sortColumns.length === 0) {
            return filteredRows;
        }
        const { columnKey, direction } = sortColumns[0];

        let sortedRows = [...filteredRows];

        switch (columnKey) {
            case 'slno':
                sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            case 'admin':
                sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            case 'app_owner':
                sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            case 'super_user':
                sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            case 'downstream':
                sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            case 'upstream':
                sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            case 'email':
                sortedRows = sortedRows.sort((a, b) => a[columnKey].localeCompare(b[columnKey]));
                break;
            case 'integrated_gas':
                sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            case 'new_energies':
                sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            case 'proj_and_tech':
                sortedRows = sortedRows.sort((a, b) => a[columnKey] - b[columnKey]);
                break;
            default:
        }
        return direction === 'DESC' ? sortedRows.reverse() : sortedRows;
    }, [filteredRows, sortColumns]);
    function infoPopupAfterClose(){
        setUserinfopopup({...userinfopopup, open: false});
    }
    return (
        <div className='position-relative'>
            <Toolbar enableFilter={true} onAddRow={onAddRow} onToggleFilter={toggleFilters} onClearFilter={clearFilters}
                addRowButtonText={'Add New User'} onRefreshClick={onRefreshClick} />
            <FilterContext.Provider value={filters}>
                <DataGrid className='' rowKeyGetter={rowKeyGetter} columns={columns} rows={sortedRowss} onRowsChange={onGridRowsUpdated} noRowsFallback={<EmptyRowsRenderer />}
                    headerRowHeight={filters.enabled ? 70 : undefined}
                    defaultColumnOptions={{
                        sortable: true,
                        resizable: true
                    }}
                    sortColumns={sortColumns}
                    onSortColumnsChange={onSortColumnsChange}
                />
            </FilterContext.Provider>
            <UserFormPopup show={openuserpopup} afterSubmit={userFormAfterSubmit} afterClose={popupAfterClose} />
            <UserInfoPopup show={userinfopopup.open} afterClose={infoPopupAfterClose} data={userinfopopup.data}/>
            {loading ? <RaceSpinner /> : null}
            <IdleTimeTracker />
        </div>
    );
}
export { FilterRenderer, EmptyRowsRenderer, rowKeyGetter, stopPropagation, togglefilterClass, FilterContext }
