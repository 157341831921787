import React, { useState, useEffect } from 'react';
function openPopup(value) {
    return `modal userinfomodal fade ${value ? "show" : ""}`
}
function UserInfoPopup(props) {
    const [open, setOpen] = useState(props.show);
    useEffect(() => {
        setOpen(props.show)
    }, [props.show])

    function onClose(_e) {
        setOpen(_open => false);
        props.afterClose(false);
    }
    return (
        <>
            <div className={openPopup(open)} id="userinfomodal" tabIndex="-1" aria-labelledby="userinfomodalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="userinfomodalLabel">{Object.keys(props.data).length > 0 ? props.data['email'] : ""}</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                Object.keys(props.data).length > 0 && props.data['linemanager'] !== undefined ?
                                    <table className="table table-sm table-borderless table-hover small">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Line Manager</th>
                                                <td>{props.data['linemanager']}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Staff</th>
                                                <td>{props.data['staff']}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Business</th>
                                                <td>{props.data['business']}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Project Supports</th>
                                                <td>{props.data['project_support']}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Access Request Date</th>
                                                <td>{props.data['requested_on']}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Business Justification</th>
                                                <td>{props.data['bizjustification']}</td>
                                            </tr>
                                        </tbody>
                                    </table> : <span className='small text-danger'>Information not available</span>
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red rounded-0" data-dismiss="modal" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {open ? <div className="modal-backdrop fade show" style={{ zIndex: 1040, backgroundColor: "rgba(3,3,3,0.6)" }}></div> : null}
        </>
    );
}
export default UserInfoPopup;
