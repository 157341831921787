import { useRef } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useEffect, useLayoutEffect as useOriginalLayoutEffect } from 'react';

// Silence silly warning
// https://reactjs.org/link/uselayouteffect-ssr
const useLayoutEffect = typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;


export function useFocusRef(isSelected) {
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (!isSelected) {
      return;
    }
    ref.current?.focus({ preventScroll: true });
  }, [isSelected]);

  return {
    ref,
    tabIndex: isSelected ? 0 : -1
  };
}
