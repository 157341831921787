import React, { useEffect, useState } from "react";
import IdleTimer from "./IdleTimer";
import { LoginUtils } from "../login";
import utils from "../app-util";
function logout() {
    window.location.replace('/dashboard', null)
    window.location.replace('/search', null)
    window.location.replace('/grid', null)
    window.location.replace('/acronyms', null)
    window.sessionStorage.clear();
    window.location.href = '/logout';
}
function onvisibilitychange() {
    const expTime = utils.states.state.getET();
    if (!document.hidden && expTime < Date.now() && parseInt(sessionStorage.getItem("_expiredTime"), 10) < Date.now()) {
        logout();
    }
    
}
export default function IdleTimeTracker() {
    const [isTimeout, setIsTimeout] = useState(false);
    function CountDownTimer(countDownDatetime) {
        const interval = setInterval(function () {
            // Get current date and time
            const now = new Date().getTime();

            // Find the distance between now and the count down date time
            const distance = countDownDatetime - now;
            if (distance > 0) {
                // calculations for days, hours, minutes and seconds
                // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                // Show output
                document.getElementById("timerdisplay").textContent = `${minutes}m ${seconds}s`;
            }
            // If the count down is over clear the interval
            if (distance < 0) {
                logout();
                clearInterval(interval);
            }
        }, 1000);
    }
    useEffect(() => {
        const timer = new IdleTimer({
            timeout: 13 * 60,
            onTimeout: () => {
                setIsTimeout(true);
                CountDownTimer(Date.now() + 2 * 60 * 1000)
            },
            onExpired: () => {
                setIsTimeout(false);
            }
        });
        document.addEventListener('visibilitychange', onvisibilitychange);
        return () => {
            timer.cleanUp();
        }

    }, []);

    function continueOnClick(_e) {
        document.querySelector(".idle-timer-modal").classList.remove("show");
        const login = new LoginUtils();
        login.getToken();
    }
    return (
        <>
            {isTimeout ?
                <div className="idle-timer-modal toast show">
                    <div className="idle-timer-modal-dialog toast-body">
                        <div className="idle-timer-modal-content">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="align-self-center my-1">Your session is going to expire in <span id="timerdisplay"></span></div>
                                <div className="align-self-center">
                                    <button type="button" className="btn btn-sm"
                                        style={{ backgroundColor: 'var(--red-bg-color)', color: 'var(--white-txt-color)' }}
                                        onClick={continueOnClick}>
                                        Continue Session
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    );
}
