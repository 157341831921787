import React from 'react';
export default function Toolbar(props) {
    function onAddRow() {
        props.onAddRow({ newRowIndex: props.numberOfRows });
    }
    return (React.createElement("div", { className: "react-grid-Toolbar" },
        React.createElement("div", { className: "tools d-flex justify-content-between align-items-end" },
            React.createElement("div", { className: "tool-left" },
                props.enableGroupBy && (React.createElement("div", { className: "d-inline-block groupby-filter"}, props.gropbyComponent || '')),
                props.onAddRow && (React.createElement("button", { type: "button", className: "btn", onClick: onAddRow }, props.addRowButtonText || 'Add Row')),
                (React.createElement("div", { className: "d-inline-block"}, 
                props.enableFilter && (React.createElement("button", { type: "button", className: "btn rdg-filter-btn", onClick: props.onToggleFilter }, props.filterRowsButtonText || 'Filter')),
                props.enableFilter &&
                (React.createElement("button", { type: "button", className: "btn rdg-clear-filter-btn", onClick: props.onClearFilter }, props.filterRowsButtonText || 'Clear Filter')),
                (React.createElement("div", { className: "user-table-refresh d-iniline-block", onClick: props.onRefreshClick }, props.refreshText || '')),)),
                props.children),
            
            React.createElement("div", { className: "tool-right" },
            props.enableDownloadButton && (React.createElement("button", { type: "button", className: "btn rdg-download-btn", onClick: props.onDownloadClick }, props.labelName || 'Export to Excel')),
            props.enableQualityDownloadButton && (React.createElement("button", { type: "button", className: "btn rdg-download-btn", onClick: props.onQualityDownloadClick }, props.labelName || 'Download Quality Most/Not relevant Data')),
                
            )
        )));
}
