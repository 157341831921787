import React from 'react';
import AccessRequestGrid from './grid/access-request-grid';
import UserDataGrid from './grid/user-data-grid';
import NavHeader from './nav-header';
class UserControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = { admin: props.location.state.admin , appOwner: props.location.state.appOwner, otheruser: props.location.state.otheruser}
    }
    render() {
        return (
            <div>
                <NavHeader admin={this.state.admin} appOwner={this.state.appOwner} otheruser={this.state.otheruser}/>
                <div className='p-2 accordion request-access-accordion'>
                    <div className='card border-0'>
                        <div className="card-header">
                            <button type='button' className='btn btn-sm btn-block btn-link btn-accordion ra-header-btn text-left font-weight-bold px-1' data-toggle="collapse"
                                data-target="#requestAccessDetail" aria-expanded="true" aria-controls="requestAccessDetail">
                                Request access details
                            </button>
                        </div>
                        <div className='collapse show' id="requestAccessDetail">
                            <AccessRequestGrid />
                        </div>
                    </div>
                </div>
                <div className="mt-4 mx-2">
                    <UserDataGrid />
                </div>
            </div>
        );
    }
}
export default UserControl
