import React from 'react'
import * as d3 from "d3";
import d3Tip from "d3-tip";

const TEXT_ANCHOR = 'text-anchor';
class ViewsBarPlot extends React.Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.msgElem = React.createRef();
        this.state = {
            empty: false
        }
        this.labelNames = {
            'views': 'Interactions', 'uniqueuser': 'Unique Users', 'queries_searched': 'Queries Searched',
            'downloads': 'Reports Downloaded', 'thematic_bowtie_views': 'Thematic Bowtie Views', 'pageviews': 'Page Views',
            'newuser': 'Total New Users Added'
        }
    }
    componentDidMount() {
        this.margin = { top: 10, right: 10, bottom: 90, left: 40 };
        this.width = this.node.current.parentElement.clientWidth - this.margin.left - this.margin.right;
        this.height = 350 - this.margin.top - this.margin.bottom;
        this.svg = d3.select(this.node.current)
            .attr("width", this.width + this.margin.left + this.margin.right)
            .attr("height", this.height + this.margin.top + this.margin.bottom)
            .append('g')
            .attr("trasnform", `translate(${this.margin.left}, ${this.height + this.margin.top})`);


        this.xScale = d3.scaleBand().range([0, this.width]).padding(0.2);
        this.yScale = d3.scaleLinear().range([this.height, 0]);
        this.xAxis = d3.axisBottom(this.xScale).tickFormat(d3.timeFormat("%d %b '%y"));
        this.yAxis = d3.axisLeft(this.yScale).tickFormat((d)=>Number.isInteger(d) ? d : '');

        this.svg.append("g").attr("class", "x axis ")
            .attr('id', "xAxis")
            .attr("transform", `translate(${this.margin.left}, ${this.height + this.margin.top})`);
        this.svg.append("g")
            .attr("class", "y axis")
            .attr('id', "yAxis")
            .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`);

        this.svg.append("text")
            .attr('class', "xlabel")
            .attr(TEXT_ANCHOR, "end")
            .attr("x", this.width / 2)
            .attr("y", this.height + this.margin.bottom - 10)
            .text("TIMESTAMP")
            .style("font-size", "12px");
        this.svg.append("text")
            .attr('class', "ylabel")
            .attr("x", -this.height / 2)
            .attr("y", 10)
            .attr("transform", "rotate(-90)")
            .attr(TEXT_ANCHOR, "start")
            .style("font-size", "12px")
            .text("");
        this.bartip = d3Tip()
            .attr("class", "d3-tooltip views-tip")
            .offset([-10, 0])
            .html(function (_d) {
                const nodeData = d3.select(this).data()[0];
                return `${nodeData.timestamp}`;
            });
        this.svg.call(this.bartip)
        this.drawBarChart(this.props.data);
    }
    componentDidUpdate() {
        if (this.props.data.isNew) {
            this.drawBarChart(this.props.data.data);
        }
        return true;
    }

    drawBarChart = (data) => {
        if (data.length > 0) {
            const plotValueType = this.props.filtertype;
            this.props.data.isNew = false;
            this.svg.style('display', 'block');
            this.msgElem.current.textContent = this.labelNames[plotValueType];
            this.xScale.domain(data.map(d => new Date(d.timestamp)));
            const max = d3.max(data, d => d[plotValueType]);
            this.yScale.domain([0, max > 0 ? max : 1]);

            this.svg.select(".rectG").remove();
            const rect = this.svg.append('g').attr('class', 'rectG').attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
                .selectAll('rect').data(data)
                .on("mouseover", this.bartip.show)
                .on("mouseout", this.bartip.hide)
            rect.join('rect').transition().duration(700)
                .attr('x', (d) => this.xScale(new Date(d.timestamp)))
                .attr('y', (d) => this.yScale(d[plotValueType]))
                .attr('width', this.xScale.bandwidth())
                .attr('height', (d) => this.height - this.yScale(d[plotValueType]))
                .attr('fill', '#009eb4')

            rect.join('text').transition().duration(700)
                .attr("x", d => this.xScale(new Date(d.timestamp)) + this.xScale.bandwidth() / 2 - 4)
                .attr("y", d => {
                    return this.yScale(d[plotValueType])
                })
                .text((d) => {
                    if (d[plotValueType] > 0) {
                        return d[plotValueType];
                    }else{
                        return '';
                    }
                })
                .style('font-size', '8px')

            this.svg.select('.x.axis').call(this.xAxis);
            this.svg.select('.y.axis').call(this.yAxis);

            this.svg.select('#xAxis').selectAll("text")
                .attr("transform", "translate(-10,0)rotate(-55)")
                .style("text-anchor", "end");
        } else {
            this.svg.style('display', 'none');
            this.msgElem.current.textContent = 'Plot data not found'
        }


    }
    render() {
        return (
            <>
                <span ref={this.msgElem} className='text-center my-1 no-data-msg-placeholder active'></span>
                <svg id="" ref={this.node} ></svg>
            </>
        );
    }

}
export default React.memo(ViewsBarPlot);
