import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader'
function RaceSpinner() {
    return (
        <div className="racespinner-wrapper text-center">
            <div className="racespinner-wrapper-inner d-flex justify-content-center align-items-center">
                <div className="racespinner shadow" style={{ backgroundColor: "var(--main-bg-color)", padding: "2rem 0px" }}>
                    <ClipLoader size={35} color={"#404040"} loading={true} />
                    <div className='mt-2 small'>Fetching results...</div>
                </div>
            </div>

        </div>
    );
}
export default RaceSpinner;
