import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';

export default function Modal({
    modalName,
    content,
    afterClose,
    show = false,
    showCloseBtn = true,
    showSubmitBtn = false,
    modalSize = "modal-xl",
    modalTitle = "",
    contentScrollable = false
}) {
    const [open, setOpen] = useState(show);
    useEffect(() => {
        setOpen(show)
    }, [show])
    function openModal(value) {
        return `modal ${modalName} ${value ? "show" : ""}`;
    }
    const onClose = useCallback(() => {
        setOpen(false);
        afterClose();
    }, [setOpen, afterClose]);
    return (
        <>
            <div className={openModal(open)} id="popupModalCustom" tabIndex="-1" aria-labelledby="popupModalCustomLabel" aria-hidden="true">
                <div className={`modal-dialog ${modalSize} ${contentScrollable ? "modal-dialog-scrollable" : ""}`}>
                    <div className="modal-content rounded-0 border-0">
                        <div className="modal-header">
                            <h5 className="modal-title" id="popupModalCustomLabel">{modalTitle}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {content}
                        </div>
                        <div className="modal-footer">
                            {showCloseBtn ? <button type="button" className="btn btn-red rounded-0" data-dismiss="modal" onClick={onClose}>Close</button> : null}
                            {showSubmitBtn ? <button type="button" className="btn btn-primary">Save</button> : null}
                        </div>
                    </div>
                </div>
            </div>
            {open ? <div className="modal-backdrop fade show" style={{ zIndex: 1040, backgroundColor: "rgba(3,3,3,0.6)" }}></div> : null}
        </>
    );
}