import React from 'react';
import { FilterRenderer } from "./user-data-grid";
const FILTER_ACTIVE_CLASS = 'filter-active';
function stopPropagation(e) {
    e.stopPropagation();
}
const textfieldRenderer = function (setFilters, field_name) {
    return (p) => (
        <>
            <FilterRenderer {...p}>
                {({ filters, ...rest }) => (
                    <input
                        {...rest}
                        className='filter-inputclass-name'
                        value={filters['field_name']}
                        onChange={(e) => {
                            let val = {};
                            val = { ...filters };
                            val[field_name] = e.target.value
                            setFilters(val)
                        }
                        }
                        onKeyDown={stopPropagation}
                    />
                )}
            </FilterRenderer>
        </>
    );
}

function boolfieldSelectRenderer(setFilters, columnKey) {
    return (p) => {
        return (
            <>
                <FilterRenderer {...p}>
                    {({ filters, ...rest }) => (
                        <select
                            {...rest}
                            className='filter-inputclass-name'
                            value={filters[columnKey]}
                            onChange={(e) => {
                                let obj = {}
                                obj = { ...filters }
                                obj[columnKey] = e.target.value
                                setFilters(obj)
                            }
                            }
                            onKeyDown={stopPropagation}
                        >
                            <option value="All">All</option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                        </select>
                    )}
                </FilterRenderer>
            </>
        );
    }
}
function textfieldSelectRenderer(setFilters, columnKey, values=[]) {
    return (p) => {
        return (
            <>
                <FilterRenderer {...p}>
                    {({ filters, ...rest }) => (
                        <select
                            {...rest}
                            className='filter-inputclass-name'
                            value={filters[columnKey]}
                            onChange={(e) => {
                                let obj = {}
                                obj = { ...filters }
                                obj[columnKey] = e.target.value
                                setFilters(obj)
                            }
                            }
                            onKeyDown={stopPropagation}
                            style={{textTransform:'capitalize'}}
                        >
                            <option value="All">All</option>
                            {
                                values.map((v,_i)=>{
                                    return <option key={v} value={v}>{v}</option>;
                                })
                            }
                        </select>
                    )}
                </FilterRenderer>
            </>
        );
    }
}
function togglefilterClass(removeClassIfExist = false) {
    const rdgFilterBtn = document.querySelector(".rdg-filter-btn"), rdgClearFilterBtn = document.querySelector(".rdg-clear-filter-btn");
    document.querySelectorAll(".rdg-cell").forEach((element) => {
        if (element.classList.contains('filter-on')) {
            element.classList.remove('filter-on');
            rdgFilterBtn.classList.remove(FILTER_ACTIVE_CLASS);
            rdgClearFilterBtn.classList.remove(FILTER_ACTIVE_CLASS);
        } else {
            // if the filter is on,if clearfilter clicked it will remove the active class i.e 'filter-on' otherwise it will always add it
            // removeClassIfExist ? element.classList.remove('filter-on') : element.classList.add('filter-on');
            if (removeClassIfExist) {
                element.classList.remove('filter-on')
                rdgFilterBtn.classList.remove(FILTER_ACTIVE_CLASS);
                rdgClearFilterBtn.classList.remove(FILTER_ACTIVE_CLASS);
            } else {
                element.classList.add('filter-on')
                rdgFilterBtn.classList.add(FILTER_ACTIVE_CLASS);
                rdgClearFilterBtn.classList.add(FILTER_ACTIVE_CLASS);
            }
        }
    });
}
function handleSortingOnHeaderCellClick(e, setsortColumns) {
    if (!e.target.classList.contains("filter-inputclass-name")) {
        // Enable filter functionality by passing the columnkey and sort direction
        const columnKey = e.currentTarget.children[0].dataset.key;

        const sort = e.currentTarget.getAttribute("aria-sort", "descending");
        if (sort === "descending") {
            setsortColumns([{ columnKey: columnKey, direction: 'ASC' }]);
        } else if (sort === "ascending") {
            setsortColumns([])
        } else {
            setsortColumns([{ columnKey: columnKey, direction: 'DESC' }]);
        }
    }
}
export {textfieldRenderer, boolfieldSelectRenderer, textfieldSelectRenderer, togglefilterClass, handleSortingOnHeaderCellClick};