import React from 'react';
export default function ActionButton(props) {
    function onClick(e) {
        props.onClick({ newRowIndex: props.rowIdx, event: e });
    }
    function editOnClick(e) {
        props.editOnClick({ newRowIndex: props.rowIdx, event: e });
    }
    return (
        <div className='react-grid-action-btn'>
            {
                props.edit ?
                    <button className='btn btn-sm' title='Edit Row' onClick={editOnClick}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6.414 16.0001L16.556 5.85808L15.142 4.44408L5 14.5861V16.0001H6.414ZM7.243 18.0001H3V13.7571L14.435 2.32208C14.6225 2.13461 14.8768 
                        2.0293 15.142 2.0293C15.4072 2.0293 15.6615 2.13461 15.849 2.32208L18.678 5.15108C18.8655 5.33861 18.9708 5.59292 18.9708 5.85808C18.9708 6.12325 
                        18.8655 6.37756 18.678 6.56508L7.243 18.0001ZM3 20.0001H21V22.0001H3V20.0001Z" fill="#A6A6A6" />
                        </svg>
                    </button> : null
            }
            <button className='btn btn-sm' onClick={onClick} title='Delete Row'><span className="icon-remove">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
                    <path d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 
                    19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 
                    14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z" fill="#A6A6A6" />
                </svg>
            </span>{props.textContent || ""}</button>
        </div>
    );
}
